import React from "react"
import { Box, Heading, Grid, Paragraph } from "grommet"

import Seo from "../components/seo"
import Layout from "../components/layout"
import SignUp from "../components/signup"
import { Add } from "grommet-icons"

export default function SignUpPage() {
  return (
    <Layout>
      <Seo title="Sign up for purpose" />
      <Box>
        <Box width="large" margin={{bottom: "xlarge"}} pad={{ horizontal: "medium"}} alignSelf="center" align="start">
          <Heading
            margin={{ bottom: "xsmall" }}
            level="2"
            size="xlarge"
          >
            Sign up for purpose
          </Heading>
          <Paragraph margin={{ bottom: "large" }} size="xlarge">
            We're writing a book, a 'how-to' for the design and delivery of
            purpose driven, successful businesses.
            <br />
            Then <strong>we're giving it away, so anyone can use it</strong>.
          </Paragraph>
          <SignUp />

          <Grid
            margin={{ top: "large", bottom: "medium" }}
            fill
            gap="small"
            columns={{
              count: "fit",
              size: "small",
            }}
          >
            <Box direction="row" gap="small">
              <Add color="brand" />
              <Paragraph fill margin={{ top: "none", bottom: "medium" }}>
                Get 1 free article per week delivered to your inbox
              </Paragraph>
            </Box>
            <Box direction="row" gap="small">
              <Add color="brand" />
              <Paragraph fill margin={{ top: "none", bottom: "medium" }}>
                Get a free book on purpose driven design
              </Paragraph>
            </Box>
            <Box direction="row" gap="small">
              <Add color="brand" />
              <Paragraph fill margin={{ top: "none", bottom: "medium" }}>
                Get Econsultancy's Best Practice Guide to Innovation
              </Paragraph>
            </Box>
          </Grid>
        </Box>
      </Box>
    </Layout>
  )
}
